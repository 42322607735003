import Head from 'next/head'
import { CMS_NAME, HOME_OG_IMAGE_URL } from '../lib/constants'
import Script from 'next/script'
import {getFavicon} from '../lib/utils'

export default function Meta({siteEnv}) {
  return (
    <Head>

      <link rel="manifest" href="/manifest.json" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={"/icons/favicon-"+getFavicon(siteEnv)+".ico"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={"/icons/favicon-"+getFavicon(siteEnv)+".ico"}
      />
      <link rel="manifest" href="/favicon/site.webmanifest" />
      <link
        rel="mask-icon"
        href="/favicon/safari-pinned-tab.svg"
        color="#000000"
      />
      <link rel="shortcut icon" href={"/icons/favicon-"+getFavicon(siteEnv)+".ico"} />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
      <meta name="theme-color" content="#ff7300" />
      

      <meta name="format-detection" content="telephone=no"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-status-bar-style" content={siteEnv == "alphalives"? "black" : "#ff7300"}/>
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <meta
        name="description"
        content={``}
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
      {/* <script defer src="/font-awesome/js/all.js"></script> */}
      

      <Script id="show-banner" strategy="lazyOnload">
      {`import 'https://cdn.jsdelivr.net/npm/@pwabuilder/pwaupdate';
   const el = document.createElement('pwa-update');
   document.body.appendChild(el);`}
      </Script>
    </Head>


  )
}
